var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-content" }, [
    _c(
      "div",
      { staticClass: "content", attrs: { id: "dragTable" } },
      [
        _c(
          "el-table",
          {
            key: _vm.tableKey,
            ref: "dragTable",
            staticClass: "table",
            attrs: {
              data: _vm.tableData,
              "row-key": "columnId",
              height: "500",
              border: "",
              "row-class-name": _vm.tableRowClassName,
            },
          },
          [
            _c("el-table-column", {
              attrs: { align: "center", width: "60", label: "序号" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "显示", width: "60", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-checkbox", {
                        staticClass: "width80",
                        attrs: {
                          disabled:
                            scope.row.fieldCaption == "序号" ||
                            scope.row.isRequired,
                        },
                        model: {
                          value: scope.row.fieldIsDisplay,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "fieldIsDisplay", $$v)
                          },
                          expression: "scope.row.fieldIsDisplay",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "默认文本",
                align: "center",
                prop: "fieldCaption",
                "min-width": "100",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "自定义文本",
                align: "center",
                prop: "fieldCustomCaption",
                "min-width": "100",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-input", {
                        attrs: { size: "mini", maxlength: "16" },
                        model: {
                          value: scope.row.fieldCustomCaption,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "fieldCustomCaption", $$v)
                          },
                          expression: "scope.row.fieldCustomCaption",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "自定义宽度(px)",
                align: "center",
                prop: "fieldCustomWidth",
                "min-width": "80",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-input", {
                        attrs: { size: "mini" },
                        on: {
                          input: function ($event) {
                            return _vm.inputLimit(
                              $event,
                              3,
                              0,
                              _vm.tableData[scope.$index],
                              "fieldCustomWidth"
                            )
                          },
                          blur: function ($event) {
                            return _vm.handleBlurInput(
                              $event,
                              scope.$index,
                              "fieldCustomWidth"
                            )
                          },
                        },
                        model: {
                          value: scope.row.fieldCustomWidth,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "fieldCustomWidth", $$v)
                          },
                          expression: "scope.row.fieldCustomWidth",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                property: "operate",
                label: "排序",
                width: "80px",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("i", {
                        staticClass: "el-icon-rank",
                        class: {
                          dragicon:
                            !scope.row.fieldFixedFront &&
                            !scope.row.fieldFixedBack,
                          "icon-rank-left": scope.row.fieldFixedFront,
                          "icon-rank-right": scope.row.fieldFixedBack,
                        },
                        staticStyle: { cursor: "pointer" },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                property: "operate",
                label: "固定列",
                width: "100px",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "operate x-fsa" }, [
                        _c(
                          "div",
                          {
                            staticClass: "operate-item x-fc",
                            class: {
                              "operate-icon": scope.row.fieldFixedFront,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleTopside(scope)
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              class: {
                                "icon-active": scope.row.fieldFixedFront,
                              },
                              attrs: { "icon-class": "fixed-left" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "operate-item x-fc",
                            class: { "operate-icon": scope.row.fieldFixedBack },
                            on: {
                              click: function ($event) {
                                return _vm.handleNethermost(scope)
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              class: {
                                "icon-active": scope.row.fieldFixedBack,
                              },
                              attrs: { "icon-class": "fixed-right" },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "dialog-footer",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "div",
          { staticClass: "dialog-footer-box x-bw" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.handleInit } },
              [_vm._v("初始化默认值")]
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$emit("dialogEvent", "dialogClose")
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.confirm()
                      },
                    },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }